






















import { LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

@Component({
  name: "sent-broadcast",
  components: {
    LottieAnimation
  }
})
export default class SentBroadcast extends mixins(
  ResponsiveMixin,
  LoadingMixin
) {}
